.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #fff;
    border-top: 1px solid #eee;
    z-index: 1000;
}

.footer a {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    font-family: Arial, sans-serif;
}

.footer a:hover {
    color: #333;
}

/* 移动端适配 */
@media (max-width: 768px) {
    .footer {
        padding: 8px 0;
    }

    .footer a {
        font-size: 12px;
    }
}