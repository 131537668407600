.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  max-width: 1360px;
  margin: 0 auto;
}

.logo {
  height: 40px;
}

nav {
  display: flex;
  gap: 100px;
  align-items: center;
}

nav a {
  text-decoration: none;
  color: #536373;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-family: "Helvetica Neue", "SF Pro Display", Arial, sans-serif;
  transition: color 0.3s ease;
  padding: 8px 0;
  position: relative;
}

/* 下划线基础样式 */
nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #1463b6;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

/* 鼠标悬停效果 */
nav a:hover {
  color: #1463b6;
}

nav a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* 活动链接样式 */
nav a.active {
  color: #1463b6;
}

/* 活动链接的下划线永久显示 */
nav a.active::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* 非活动链接悬停时的下划线动画 */
nav a:not(.active):hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* 移动端响应式样式 */
@media (max-width: 768px) {
  .header {
      padding: 10px 15px;
      width: 100%;
      max-width: 100%;
  }

  .logo {
      height: 30px;
  }

  nav {
      gap: 15px;
      margin-left: 10px;
  }

  nav a {
      font-size: 14px;
      padding: 5px 0;
      white-space: nowrap;
  }

  nav a::after {
      height: 2px;
  }
}

/* 更小屏幕的适配 */
@media (max-width: 375px) {
  .header {
      padding: 8px 10px;
  }

  .logo {
      height: 25px;
  }

  nav {
      gap: 10px;
  }

  nav a {
      font-size: 12px;
  }
}