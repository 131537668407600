.company-intro {
    font-family: 'Microsoft YaHei', Arial, sans-serif;
    max-width: 1400px;  /* 调整最大宽度 */
    margin: 0 auto;
    padding: 50px 40px;
    background-color: #ffffff;
    overflow: hidden;
}

.content1 {
    line-height: 1.8;
    text-align: left;  /* 确保文本左对齐 */
}

.intro {
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
    text-indent: 2em;  /* 添加首行缩进 */
}

.services {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding-left: 65px;
}

.services li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    text-indent: 3em;  /* 添加首行缩进 */
    margin-left: -2em;  /* 补偿缩进，使符号对齐 */
}

.bullet {
    color: #000;
    margin-right: 30px;
    font-size: 22px;
}

.service-content {
    flex: 1;
    font-size: 22px;
    text-indent:0
}

.services strong {
    font-weight: bold;
    color: #000;
}

.imove {
    color: #ff0000;
    font-weight: bold;
}

.contact-info {
    margin-top: 30px;
}

.contact-info p {
    margin: 10px 0;
    font-size: 22px;
    color: #333;
    text-indent: 2em;  /* 添加首行缩进 */
}

.contact-info strong {
    font-weight: bold;
}

/* ... 保持其他样式不变 ... */

/* 移动端响应式设计 */
@media (max-width: 768px) {
    .company-intro {
        margin: 10px;
        padding:40px 15px;
    }

    .intro {
        font-size: 14px;
        margin-bottom: 15px;
        text-indent: 1em;
    }

    .services {
        padding-left: 20px;
    }

    .services li {
        font-size: 14px;
        margin-bottom: 10px;
        text-indent: 0;
        margin-left: 0;
        align-items: flex-start;
    }

    .bullet {
        margin-right: 10px;
        font-size: 14px;
        flex-shrink: 0;
    }

    .service-content {
        font-size: 14px;
        line-height: 1.5;
        flex: 1;
    }

    .contact-info {
        margin-top: 20px;
    }

    .contact-info p {
        font-size: 14px;
        margin: 8px 0;
        text-indent: 1em;
    }
}

/* 更小屏幕的适配 */
@media (max-width: 375px) {
    .company-intro {
        padding: 40px 10px;
    }

    .intro {
        font-size: 12px;
    }

    .services li {
        font-size: 12px;
    }

    .bullet {
        font-size: 12px;
    }

    .service-content {
        font-size: 12px;
    }

    .contact-info p {
        font-size: 12px;
    }
}
.company-intro::-webkit-scrollbar {
    width: 6px;
}

.company-intro::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.company-intro::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.company-intro::-webkit-scrollbar-thumb:hover {
    background: #555;
}