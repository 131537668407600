.theory {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    height: calc(100vh - 65px); /* 减去header高度 */
    overflow-y: auto;
    margin-top: 65px; /* 为header留出空间 */
}

.content-wrapper {
    max-height: calc(100vh - 60px);
    padding-right: 30px;  /* 增加右侧内边距 */
}

.content-wrapper::-webkit-scrollbar {
    width: 12px;  /* 增加滚动条宽度 */
}

.content-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.content-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

section {
    margin-bottom: 50px;  /* 增加段落间距 */
}

h2 {
    color: #0056b3;
    border-bottom: 3px solid #0056b3;  /* 加粗底边框 */
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-size: 2.2em;  /* 增大标题字体 */
    font-weight: bold;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 25px;  /* 增加列表项间距 */
    border-radius: 8px;
    transition: background-color 0.3s;
}

li:hover {
    background-color: #f8f9fa;  /* 添加悬停效果 */
}

p {
    color: #333;
    margin: 0;
    line-height: 1.8;  /* 增加行高 */
    font-size: 1.3em;  /* 增大正文字体 */
}

.doi {
    color: #0056b3;
    font-style: italic;
    font-size: 1.2em;  /* 增大 DOI 字体 */
    margin-left: 10px;
}

.note {
    color: #666;
    font-size: 1.2em;  /* 增大注释字体 */
    margin-left: 10px;
}

/* 大屏幕样式 */
@media (min-width: 1400px) {
    .theory {
        padding: 40px;
    }

    h2 {
        font-size: 2.5em;  /* 更大的标题 */
    }

    p {
        font-size: 1.4em;  /* 更大的正文 */
    }

    .doi, .note {
        font-size: 1.3em;
    }
}

.theory {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    height: calc(100vh - 65px); /* 减去header高度 */
    overflow-y: auto;
    margin-top: 65px; /* 为header留出空间 */
}

/* ... 保持其他样式不变 ... */

/* 移动端样式 */
@media (max-width: 768px) {
    .theory {
        padding: 15px;
        margin-top: 50px; /* 调整顶部间距 */
        height: calc(100vh - 50px);
    }

    .content-wrapper {
        height: auto;
        padding-right: 0;
    }

    h2 {
        font-size: 16px;
        padding-bottom: 8px;
        margin-bottom: 15px;
        border-bottom: 2px solid #0056b3;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 15px;
        padding: 5px;
        font-size: 14px;
    }

    p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 5px;
    }

    .doi, .note {
        font-size: 12px;
        display: block;
        margin: 5px 0;
        color: #666;
    }
}

/* 更小屏幕的适配 */
@media (max-width: 375px) {
    .theory {
        padding: 10px;
    }

    h2 {
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }

    .doi, .note {
        font-size: 11px;
    }
}

/* 自定义滚动条样式 */
.theory::-webkit-scrollbar {
    width: 4px;
}

.theory::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.theory::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

.theory::-webkit-scrollbar-thumb:hover {
    background: #555;
}