.home {
  max-width: 1400px;
  margin: 80px auto 0;
  padding: 40px 20px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue", "SF Pro Display", Arial, sans-serif;
  background: linear-gradient(to bottom, #ffffff, #f7f7f8);
  position: relative;
}

/* 添加背景装饰 */
.home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
      radial-gradient(circle at 10% 20%, rgba(83, 99, 115, 0.02) 0%, transparent 70%),
      radial-gradient(circle at 90% 80%, rgba(83, 99, 115, 0.02) 0%, transparent 70%);
  z-index: -1;
}

.home_ {
  background: url('./big.jpg');
  height: 400px;
  background-size: cover;
  background-position: center;
}

.title {
  text-align: center;
  font-size: 42px;
  font-weight: 300;
  color: #2b3b4c;
  margin: 60px 0;
  line-height: 1.3;
  letter-spacing: -0.2px;
}

.main-buttons {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin: 60px 0;
}

.main-button {
  text-decoration: none;
  padding: 20px 60px;
  font-size: 20px;
  color: #536373;
  background-color: #e0ecbf;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  transition: all 0.2s ease;
  text-align: center;
  min-width: 200px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.main-button:hover {
  color: #2b3b4c;
  border-color: #2b3b4c;
  background-color: rgba(43, 59, 76, 0.02);
}

.disclaimer {
  margin-top: auto;
  margin-top: 100px !important;
  padding: 30px;
  background-color: #f7f7f8;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  line-height: 1.7;
  color: #536373;
}

.disclaimer a {
  color: #2b3b4c;
  font-weight: 500;
  letter-spacing: 0.2px;
}

/* ... 保持其他样式不变 ... */

/* 移动端响应式样式 */
/* ... 保持其他样式不变 ... */

/* 移动端响应式样式 */
@media (max-width: 768px) {
  .home {
      margin: 0;
      padding: 10px;
      overflow: auto;
  }

  .home_ {
      height: auto;
      background-size: cover;
      background-position: center;
      padding: 20px 10px;
  }

  .title {
      font-size: 20px;
      margin: 20px 0;
      color: #000;
  }

  .main-buttons {
    display: flex !important;
      flex-direction: row !important;
      justify-content: center;
      gap: 15px; /* 减小间距 */
      margin: 30px auto;
      width: 100%;
  }

  .main-button {
      padding: 15px 30px !important; /* 更小的内边距 */
      font-size: 1px!important; /* 更小的字体 */
      width: auto;
      min-width: 90px!important;/* 减小最小宽度 */
      border-radius: 15px; /* 更小的圆角 */
      letter-spacing: 0;
  }

  .disclaimer {
      margin-top: 30px !important;
      padding: 15px;
      font-size: 14px;
      line-height: 1.5;
      border-radius: 8px;
      background-color: rgba(247, 247, 248, 0.9);
  }
}

/* 更小屏幕的适配 */
@media (max-width: 375px) {
  .main-buttons {
      gap: 10px;
  }

  .title {
      font-size: 18px;
  }

  .main-button {
      padding: 5px 10px; /* 更小的内边距 */
      font-size: 11px; /* 更小的字体 */
      min-width: 80px; /* 更小的最小宽度 */
  }

  .disclaimer {
      font-size: 12px;
  }
}