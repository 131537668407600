.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-top: 65px;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .content {
      margin-top: 26px;
  }
}