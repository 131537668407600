.entrepreneur {
    font-family: 'SimSun', serif;
    padding: 20px;
    margin: 0 auto;
    max-width: 1000px;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: 20px;
    border: 2px solid #000; /* 外边框 */
}

th, td {
    border: 1px solid #000; /* 内边框 */
    padding: 8px;
    text-align: left;
    font-size: 18px;
    line-height: 1.4;
}

/* 表头样式 */
th {
    background-color: #fff;
    font-weight: normal;
    text-align: center;
    border: 2px solid #000;
}

/* 列宽设置 */
.col-variable {
    width: 80px;
    text-align: center;
}

.col-question {
    width: auto;
}

.col-score {
    width: 300px;
    text-align: center;
    background-color: #90EE90; /* 绿色背景 */
}

input[type="number"] {
    width: 200px; /* 调整宽度 */
    padding: 0;
    margin: 0;
    border: none; /* 移除边框 */
    text-align: center;
    font-size: 16px;
    background-color: transparent;
    appearance: none;
    -moz-appearance: textfield; /* Firefox */
    outline: none; /* 移除聚焦时的边框 */
}

/* 去除输入框的箭头 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 按钮容器和按钮样式 */
.button-container {
    text-align: center;
    margin-top: 20px;
}

button {
    padding: 8px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #45a049;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 1px !important;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .entrepreneur {
        padding: 10px;
    }

    th, td {
        padding: 5px;
        font-size: 16px;
    }

    .col-variable {
        width: 60px;
    }

    .col-score {
        width: 100px;
    }

    input[type="number"] {
        width: 40px; /* 调整移动端宽度 */
    }
}