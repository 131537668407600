
.home {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 60px;
}



.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  border: 2px solid #000;
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
}

.title {
  text-align: center;
  font-size: 28px;
  margin: 40px 0;
}

.main-buttons2 {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin: 150px 0
}

.main-buttons1 {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: -100px;
}
.main-button {
  border: 1px solid #000;
  padding: 18px 73px;
  text-decoration: none;
  color: #000;
  font-size: 32px;
  
}
.main-button1 {
  border: 3px solid #000;
  padding: 18px 73px;
  text-decoration: none;
  color: #000;
  font-size: 20px;
}
.disclaimer {
  margin-top: auto;
  font-size: 24px;
  line-height: 1.6;
  color: #000;
  padding: 20px 0;
  
}





.main-button11 {
  display: inline-block;
  font-size: 38px;
  padding: 34px 105px;
  /* background: linear-gradient(135deg, #6e8efb, #a777e3); */
  color: #000;
  /* font-size: 20px; */
  font-weight: bold;
  border: none;
  /* border-radius: 50px; */
  text-align: center;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.main-button11::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.75s ease;
}

.main-button11:hover::before {
  width: 0;
  height: 0;
}

.main-button11:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
}


/* 移动端响应式样式 */
@media (max-width: 768px) {
  .home {
      padding: 10px;
      margin-top: 0;
  }

  .nav-container {
      margin-bottom: 20px;
      padding: 10px 0;
  }

  .title {
      font-size: 18px;
      margin: 20px 0;
      padding: 0 10px;
  }

  .main-buttons1 {
      margin-top: -50px;
      gap: 20px;
  }

  .main-buttons2 {
      margin: 50px 0;
      gap: 20px;
  }

  .main-button {
      padding: 10px 30px;
      font-size: 16px;
      width: auto;
  }

  .main-button1 {
      padding: 8px 20px;
      font-size: 14px;
      border-width: 2px;
  }

  .main-button11 {
      font-size: 20px;
      padding: 15px 40px;
      margin-top: 30px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .disclaimer {
      font-size: 14px;
      padding: 10px 0;
      margin-top: 20px;
  }
}

/* 更小屏幕的适配 */
@media (max-width: 375px) {
  .home {
      padding: 8px;
  }

  .title {
      font-size: 16px;
  }

  .main-button {
      padding: 8px 20px;
      font-size: 14px;
  }

  .main-button1 {
      padding: 6px 15px;
      font-size: 12px;
  }

  .main-button11 {
      font-size: 18px;
      margin-top: 30px;
      padding: 12px 30px;
  }

  .disclaimer {
      font-size: 12px;
  }
}